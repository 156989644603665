import React from 'react';
import { Container } from 'react-bootstrap';
import CustomScripts from './custom-scripts';
import FooterTop from './FooterTop';
import FooterContact from './FooterContact';
import FooterPopularSearch from './FooterPopularSearch';
import FooterBottom from './FooterBottom';
import PropTypes from "prop-types";
import './styles/_index.scss';

const Footer = (props) => {
 const { footerDetails, ...restProps } = props;
 const { Footer_Box_Content: topContent, Footer_Office_Address: contactContent, ...bottomContent } = footerDetails;

  return (
    <footer className="footer spacing-default">
      <Container>        
        <CustomScripts />
        <FooterTop topContent={topContent} />
        <FooterContact contactContent={contactContent}  />
        <FooterPopularSearch {...restProps} />
        <FooterBottom  bottomContent ={bottomContent} /> 
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}
export default Footer;