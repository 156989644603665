import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames'
import { Link } from "gatsby";
import { Nav, Container, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";
import "animate.css/animate.min.css";

/**
* Assets
*/

import './styles/_index.scss';
import $ from "jquery";
/**
* Components
*/
import { Logo, PlusDark, Minus } from "../icon/icon";

function ContextAwareToggle({ children, eventKey, callback }) {
    useEffect(() => {
        $(".open-search-overlay").click(function () {
            $('.triggersearchoverlay').click();
        });
        $(".thrid-level").each(function () {
            var thirdlevel = $(this).find("li");
            //console.log('aaaaaaaaa',thirdlevel.length)
            var ifthirdlevel = thirdlevel.length
            if (ifthirdlevel > 0) {
                $(this).parent().addClass('has-dropdown');
            }
        });
        //toggle the component with class thrid-level
        $(".open-third-level").click(function (e) {
            var checkactive = $(this)[0].className;
            if (checkactive.indexOf('active') != -1) {
                $('.open-third-level').removeClass('active');
                $('li.second-level.has-dropdown .thrid-level').stop(true, true).slideUp();
                //$('li.second-level.has-dropdown .open-third-level').find('.plusminus').html(<PlusDark />);
                $('li.second-level.has-dropdown .open-third-level .plusminus .PlusDark').removeClass('hidden');
                $('li.second-level.has-dropdown .open-third-level .plusminus .minusDark').addClass('hidden');
                e.stopPropagation();
            } else {
                $('.open-third-level').removeClass('active');
                //$('li.second-level.has-dropdown .open-third-level').find('.plusminus').html(<PlusDark />);
                $('li.second-level.has-dropdown .open-third-level .plusminus .PlusDark').removeClass('hidden');
                $('li.second-level.has-dropdown .open-third-level .plusminus .minusDark').addClass('hidden');

                $(this).toggleClass("active");
                $(this).next('.thrid-level').slideDown();
                //$(this).find('.plusminus').html(<Minus />);
                $(".plusminus .minusDark", this).removeClass('hidden');
                $(".plusminus .PlusDark", this).addClass('hidden');
                e.stopPropagation();
            }
        });
    }, []);

    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            className={classNames('toggle-button', { 'active': isCurrentEventKey })}
            onClick={decoratedOnClick}
        ><span className="d-none">toggle-button</span>{isCurrentEventKey ? <Minus /> : <PlusDark />} </button>
    );
}

const ModalNav = (props) => {
    const active = props.active;

    useEffect(() => {
        return function () {
            console.log(1)
            document.querySelectorAll('body,html').forEach(item => item.classList.remove('overflow'))
        }
    }, []) 
    return (
        <>
 
            <div className={classNames('modal-nav', { 'active': active })}>
                <Container fluid>
                    <div className="header-top">
                        <Link to="/" className="logo"><Logo /></Link>

                    </div>
                    <div className="header-bottom">

                        <div className="menu-modal-wrap">

                            <div className="menu-enclose">
                                <Nav>
                                    <Accordion defaultActiveKey="All-menus_5fc09f6729e1721c1dc7f0ba">
                                        <ul className="parent-menu">

                                            {props.BurgerMenu.edges.map(({ node }, key) => {
                                                return (
                                                    <>
                                                        <li className="parent-level-menu">
                                                            <div className="first-level-menu">
                                                                <Link to={`${node.Menu_Link}`}>{node.Menu_Name}</Link>
                                                                {node.SubMenu && node.SubMenu.length > 0 &&
                                                                    <ContextAwareToggle eventKey={key} />
                                                                }
                                                            </div>
                                                            <Accordion.Collapse eventKey={key}>

                                                                <Nav className="dropdown-list">
                                                                    <ul>
                                                                        {node.SubMenu && node.SubMenu.length > 0 && node.SubMenu.map((submenu, key2) => {
                                                                            return <>
                                                                                <li className="second-level">
                                                                                    <Link to={`${submenu.MenuLink}`}>{submenu.MenuName}</Link>
                                                                                </li>
                                                                            </>
                                                                        })}

                                                                    </ul>
                                                                </Nav>
                                                            </Accordion.Collapse>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                        </ul> 
                                    </Accordion>
                                </Nav> 
                            </div>
                        </div> 
                    </div>
                    {process.env.GATSBY_MYACCOUNT_URL && (
                        <div className="header-cta">
                            <Link className="btn" to={process.env.GATSBY_MYACCOUNT_URL} target="_blank">Sign in/Register</Link>
                        </div>
                    )}

                </Container>
            </div>
        </>
    )
}

export default ModalNav