/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet";

import 'bootstrap/dist/css/bootstrap.min.css';
import "@styles/main.scss";
import "@styles/custom.scss";

import Header from '@components/header/header';
import Footer from '@components/footer/footer';

const Layout = (props) => {

  //console.log("page Type", props)


  const data = useStaticQuery(graphql`
    query SiteTitleQuery {  
      site {
        siteMetadata {
          title
        }
      }
      headerTopSection: strapiGlobalConfig {
        Header_Top_Section {
          id
          Label
          Link
          Type
          Show_Icon
          Icon_Type
          Hide_Mobile
        }
      }
      strapiGlobalConfig {
        Footer_Box_Content
        Footer_Office_Address
        Instagram_Link
        Linkedin_Link
        Twitter_Link
        Facebook_Link
        Popular_Search_Static_Rentals
        Popular_Search_Static_Common
        Popular_Search_Static_Sales
        Get_In_Touch_Content
      }
      allStrapiTopNavigations(filter: {Sort: {}}, sort: {order: ASC, fields: Sort}) {
        edges {
        node {
          Menu_Name
          Menu_Link
          Show_In_Header
          SubMenu {
            id
            MenuLink
            MenuName
          }
        }
      }
    }
   
    }
  `);
  return (
    <>
    <Helmet bodyAttributes={{ class: props.classAttribute || '' }} />
    <div id="main-wrapper" className={props?.pageType==="home"? 'home-page' : props?.propertydetails?'page-propertydetails':'inner-page'}>
      <Header headertype={props.headertype} Topmenus={data.allStrapiTopNavigations} HeaderTopSection={data.headerTopSection.Header_Top_Section}/>
      <main>{props.children}</main>
      <Footer footerDetails={data.strapiGlobalConfig} popularSearch={props.popularSearch} area={props.area} office={props.office} guides={props.guides} searchtype={props.searchtype} propertydetails={props.propertydetails} typeText={props.typeText} bedtypeText={props.bedtypeText} searchbed={props.searchbed} ptype={props.ptype} />
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
