import React, { useEffect, useState } from "react";
import Helmet from "react-helmet"

const StaticReviews = () => {
  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    const handleEvent = () => {
      if (!renderComponent) {
        setRenderComponent(true);
      }
    };

    window.addEventListener("mousemove", handleEvent);
    window.addEventListener("keypress", handleEvent);
    window.addEventListener("touchmove", handleEvent);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleEvent);
      window.removeEventListener("keypress", handleEvent);
      window.removeEventListener("touchmove", handleEvent);
    };
  }, [renderComponent]); // Only run when renderComponent changes


  return (
    <>
      <Helmet>
        <script type="text/javascript" async="" src="https://www.googletagmanager.com/gtag/js?id=G-LBCG0DWPGK&amp;cx=c&amp;_slc=1"></script>
        <script type="text/javascript" async="" src="https://www.googletagmanager.com/gtag/js?id=G-LBCG0DWPGK&amp;l=dataLayer&amp;cx=c"></script>
        <script type="text/javascript" src="https://www.google-analytics.com/analytics.js"></script>
        <script type="text/javascript" src="https://www.googletagmanager.com/gtm.js?id=GTM-NWJGQ23"></script>
        <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];window.dataLayer.push({"platform":"gatsby"}); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NWJGQ23');`}</script>
      </Helmet>
      {renderComponent &&
        <>
          <Helmet 
            script={[{
              type: "text/plain",
              innerHTML: '(function() {  gtag("event", "conversion", {"send_to": "AW-10960411512/9CJyCMm89NMDEPi2quoo"}); }) ();',
              "data-cookie-consent": 'tracking'
            }]} />
          <Helmet
            script={[{
              type: "text/javascript",
              innerHTML: '(function() {  gtag("event", "conversion", {"send_to": "AW-10960411512/9CJyCMm89NMDEPi2quoo"}); }) ();'
            }]} />

          <Helmet
            script={[{ type: "text/javascript", innerHTML: '(function() { window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "AW-10960411512"); }) ();', "data-cookie-consent": 'tracking' }]} />

          <Helmet
            script={[{ type: "text/javascript", innerHTML: '(function() { async src="https://www.googletagmanager.com/gtag/js?id=AW-10960411512" }) ();', "data-cookie-consent": 'tracking' }]} />

          <Helmet
            script={[{
              type: "text/javascript",
              innerHTML: '(function() {var se = document.createElement("script"); se.type = "text/javascript"; se.async = true; se.src ="//storage.googleapis.com/moneypennychat/js/	2b334a16-b428-4d60-b5b0-92b1db5c1f0b.js"; var done = false; se.onload = se.onreadystatechange = function() {if (!done&&(!this.readyState||this.readyState==="loaded"||this.readyState==="complete")) {done = true;var seAgent;Moneypenny.setCallback("StartChat", function(email, msg, type) { if (type == "proactive") { ga("send","event","Moneypenny", "proactiveEngaged", "Proactive Start Chat");}});Moneypenny.setCallback("StartChat", function(msg) { ga("send","event","Moneypenny", "proactiveEngaged", "Manual Start Chat");});}};var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(se, s);})();'
            }]} />
          <Helmet
            script={[{
              type: "text/javascript",
              innerHTML: '(function() { window.skillStore = { clientId: "pcm_64Yg7" }; var s1 = document.createElement("script"), s2 = document.createElement("script"), s0 = document.getElementsByTagName("body"); s1.async = true; s2.async = true; s1.src = "https://videos.theskill.store/index.js"; s2.src = "https://videos.theskill.store/ddt.min.js"; s1.charset = "UTF-8"; s2.charset = "UTF-8"; s1.setAttribute("crossorigin", "*"); s2.setAttribute("crossorigin", "*"); s0[0].appendChild(s1); s0[0].appendChild(s2); var l1 = document.createElement("link"), l2 = document.createElement("link"), l0 = document.getElementsByTagName("head"); l1.rel = "stylesheet"; l1.type = "text/css"; l1.href = "https://videos.theskill.store/index.css"; l2.rel = "stylesheet"; l2.type = "text/css"; l2.href = "https://fonts.googleapis.com/css?family=Roboto"; l0[0].appendChild(l2); l0[0].appendChild(l1); })();'
            }]} />

        </>
      }      
    </>
  )
}
export default StaticReviews
