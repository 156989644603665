import React from 'react';
import HtmlParser from 'react-html-parser';

const FooterContact = ({contactContent}) => {
  return (
    <div className="footer-contact">
        { contactContent && HtmlParser(contactContent)}
    </div>
  );
};

export default FooterContact;