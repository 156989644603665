import React from 'react';
import { PhoneIcon, UserIcon } from '../icon/icon';
import './IconRenderer.scss';
const IconRenderer = ({ iconType }) => {
  const renderIcon = () => {
    switch (iconType) {
      case 'PhoneIcon':
        return <PhoneIcon />;
      case 'UserIcon':
        return <UserIcon />;
      case 'PhoneBGIcon':
          return <div className="bg-icon phone-bg-icon"></div>;
      case 'EmailBGIcon':
          return <div className="bg-icon email-bg-icon"></div>;
      case 'WhatsAppBGIcon':
          return <div className="bg-icon whatsapp-bg-icon"></div>;
      default:
        return null;
    }
  };

  return renderIcon();
};

export default IconRenderer;
