import React from 'react';
import HtmlParser from 'react-html-parser';

const FooterTop = ({topContent}) => {
  return (
    <div className="footer-top">
       {topContent && HtmlParser(topContent)} 
    </div>
  );
};

export default FooterTop;