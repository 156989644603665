import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import PopularSearchDetails from "../popular-search-details";
import PopularSearchStaticDetails from "../popular-search-static-details";
import PopularSearchDynamic from "../popular-search-dynamic";
import PopularSearch from "../popular-search-static";
import { ChevronDown } from '../icon/icon';
import classnames from 'classnames';

const FooterPopularSearch = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const Activesearch = () => {
    setIsOpen(!isOpen);
  }
  // Default value for props.popularSearch if it's null
  const popularSearchType = props.popularSearch || 'Popular_Search_Static_Common';

  return (
    <div className="footer-popular-search spacing-64">
        <Accordion className="spacing-32">
          <Accordion.Toggle 
            onClick={Activesearch} 
            className={classnames("popular-search-btn text-20 p-0", { searchopen: isOpen })} 
            variant="link" 
            eventKey="1"
          >
            Popular Searches <span><ChevronDown /></span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <section className="popular-searchlist text-18">
              {props.popularSearch === 'propertydetails' && (
                <PopularSearchDetails propertydetails={props.propertydetails} />
              )}
              {props.popularSearch === 'staticdetails' && (
                <PopularSearchStaticDetails office={props.office} area={props.area} guides={props.guides} />
              )}
              {props.popularSearch === 'propertyresults' ? (
                <PopularSearchDynamic searchtype={props.searchtype} />
              ) : (
                <PopularSearch popularSearch={popularSearchType} />
              )}
            </section>
          </Accordion.Collapse>
        </Accordion>
    </div>
  );
};

export default FooterPopularSearch;
