import React from 'react';
import IconRenderer from '../IconRenderer/IconRenderer';

const LinkRenderer = ({ item }) => {
  const renderLink = () => {
    if (item.Type === 'Link' || item.Type === 'ExternalLink') {
      return (
        <>
          <a href={item.Link} target={item.Type === 'ExternalLink' ? '_blank' : '_self'} rel={item.Type === 'ExternalLink' ? 'noopener noreferrer' : null}>{item?.Show_Icon && <span className="user-icon top-header__icon"><IconRenderer iconType={item?.Icon_Type} /></span>}<span className='item-label'>{item.Label}</span>
          </a>
        </>
      );
    } else if (item.Type === 'Phone') {
      return (
        <>        
          <a href={`tel:${item.Label}`}>{item?.Show_Icon && <span className="phone-icon top-header__icon"><IconRenderer iconType={item?.Icon_Type} /></span>}<span className='item-label'>{item.Label}</span></a>
        </>
      );
    } else if (item.Type === 'Email') {
      return (
        <a href={`mailto:${item.Label}`}>{item.Label}</a>
      );
    } else {
      return <span>{item.Label}</span>;
    }
  };

  return renderLink();
};

export default LinkRenderer;

