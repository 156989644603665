import React from 'react';
import { Link } from 'gatsby';
import { Twitter, Facebook, Instagram, Linkedin } from '../icon/icon';

const FooterBottom = ({bottomContent}) => {
  return (
    <div className="footer-bottom">
          <div className="copy text-18">
            <ul >
              <li><Link className='text-18' to="/terms-and-condition/">Terms</Link><span className="link-separator">|</span></li>
              <li><Link className='text-18' to="/privacy-policy/">Privacy Policy</Link><span className="link-separator">|</span></li>
              <li><Link className='text-18'to="/cookie-policy/">Cookie Policy</Link><span className="link-separator">|</span></li>
              <li><Link className='text-18' to="/sitemap/">Sitemap</Link><span className="link-separator">|</span></li>
              <li><a className='text-18' target="_blank" href="https://ggfx-anthonypepe.s3.eu-west-2.amazonaws.com/i.prod/Client_Money_Protection_Security_Certificate_Anthony_Pepe_a77bd5700e.pdf">CMP Security</a></li>
            </ul>
            <p className="copy text-18 spacing-bottom-32">© {new Date().getFullYear()} Anthony Pepe. All Rights Reserved.</p>
            <p className="site-by text-18 m-0">
              Site by <a href="https://starberry.tv/" target="_blank">Starberry</a>
            </p>
          </div>
          <div>
            <div className="socials">
              <ul>
                <li><a href={bottomContent?.Twitter_Link} target="_blank"  rel="noreferrer"><span>Twitter</span> <Twitter /></a></li>
                <li><a href={bottomContent?.Facebook_Link} target="_blank"  rel="noreferrer"><span>Facebook</span> <Facebook /></a></li>
                <li><a href={bottomContent?.Instagram_Link} target="_blank"  rel="noreferrer"><span>Instagram</span> <Instagram /></a></li>
                <li><a href={bottomContent?.Linkedin_Link} target="_blank"  rel="noreferrer"><span>Linkedin</span> <Linkedin /></a></li>
              </ul>
            </div>
          </div>
    </div>
  );
};

export default FooterBottom;