import React from 'react';
import { Container } from "react-bootstrap";
import LinkRenderer from '../LinkRenderer/LinkRenderer';
import './styles/_index.scss';

const HeaderTop = ({ sectionData }) => {
  return (
    <Container fluid>
    <div className="top-header">
        <ul className="menu-list">
          {sectionData.map((item, index) => (
            <React.Fragment key={item.id}>
              <li className={item.Hide_Mobile ? 'hide-mobile' : ''}><LinkRenderer item={item} /></li>
              {index === sectionData.length - 3 && (
                <li className="link-separator hide-mobile">|</li>
              )}
            </React.Fragment>
          ))}
        </ul>
    </div>
    </Container>
  );
};

export default HeaderTop;